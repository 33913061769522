<template>
  <div class="wrapper">
      配置
  </div>
</template>

<script>
export default {
  name: "setting",
};
</script>


